import React from 'react'

import Intro from '@components/ui/Intro'

const Hero = () => (
  <header className="hero t-center">
    <Intro pageTitle="Get Involved" wrapper="wrapper-s">
      <p>
        Ok so you’re clearly on the edge of your seat and looking for ways to
        get involved. FOMO? Check. If you’re looking to be part of a community
        of passionate, brainy, idealists then take a peek below at the ways you
        can contribute to MOSS.
      </p>
    </Intro>
  </header>
)

export default Hero
