import React from 'react'

import Intro from '@components/ui/Intro'
import styles from './JoinUs.module.scss'

const JoinUs = () => (
  <section className="section wrapper-l">
    <Intro sectionTitle="Join Us" wrapper="wrapper-s">
      <p>We need talent.</p>

      <p>
        That came out wrong. What we mean is: we have some great qualities in
        our team, but are always talent-spotting.
      </p>
    </Intro>

    <div className={styles.sectionsWrapper}>
      <div className={styles.volunteersSection} id="volunteer">
        <div className={styles.section__copy}>
          <h3 className="t-heading-s">Volunteers</h3>

          <p>
            MOSS is always on the lookout for interns because interns make MOSS
            happen. They are crucial human resources that are needed from time
            to time for specialist projects.
          </p>

          <p>
            We have a number of projects that could use a smart, talented,
            individual like yourself to take it to the next level.
          </p>
        </div>

        <div className={styles.cta}>
          <a
            href="mailto:info@mosscc.org?subject=Get%20Involved%20Volunteer%20request"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apply now!
          </a>
        </div>
      </div>

      <div className={styles.vacanciesSection} id="vacancies">
        <div className={styles.section__copy}>
          <h3 className="t-heading-s">Vacancies</h3>

          <p>There are no vacancies at this time. </p>

          <p>
            Well that’s not entirely true. There are lots of vacancies that we
            cannot quite afford at this stage. There might even be vacancies for
            roles we didn’t even know we needed.
          </p>

          <p>Still interested?</p>
        </div>

        <div className={styles.cta}>
          <a
            href="mailto:info@mosscc.org?subject=Get%20Involved%20Volunteer%20request"
            target="_blank"
            rel="noopener noreferrer"
          >
            Let's Talk!
          </a>
        </div>
      </div>
    </div>
  </section>
)

export default JoinUs
