import React from 'react'

import Connect from '@components/pages/get-involved/Connect'
import Hero from '@components/pages/get-involved/Hero'
import JoinUs from '@components/pages/get-involved/JoinUs'
import Root from '@layouts/Root'
import Seo from '@components/utils/Seo'
import Support from '@components/pages/get-involved/Support'

const IndexPage = () => (
  <Root>
    <Seo title="Get Involved" />

    <Hero />
    <div className="wrapper-l section">
      <hr />
    </div>
    <JoinUs />
    <Connect />
    <Support />
  </Root>
)

export default IndexPage
