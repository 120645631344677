import React from 'react'

import styles from './Connect.module.scss'

const Connect = () => {
  return (
    <section className="section wrapper-l t-center">
      <div className={styles.wrapper}>
        <h2 className={styles.heading}>Connect with Us</h2>

        <p>
          Life is all about who you know. Friends. Family. Influencers.
          <br />
          Whichever you want to be to us is up to you. Or just be all!
        </p>

        <a
          href="mailto:info@mosscc.org"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.dropline}
        >
          Drop us a line sometime!
        </a>
      </div>
    </section>
  )
}

export default Connect
