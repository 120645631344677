import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import Intro from '@components/ui/Intro'
import social from '@assets/social'
import styles from './Support.module.scss'

const Support = () => {
  const data = useStaticQuery(graphql`
    query supportQuery {
      merch1: file(relativePath: { eq: "images/supportMerch1.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="section">
      <Intro sectionTitle="Support Us" wrapper="wrapper-s">
        <p>
          Stand by your MOSS. There are many ways in which you can support us,
          even if it’s just telling others nice things about us.
        </p>
      </Intro>

      <div className="wrapper-s subsection t-center">
        <h3 className="t-heading-m">Spread the word</h3>

        <div className={styles.social}>
          <p className="t-italic">
            Like, follow, share, repeat. You know the drill.
          </p>

          {social.map((entry) => (
            <a
              className={styles.social__item}
              href={entry.url}
              key={entry.url}
              rel="noopener noreferrer"
              title={entry.title}
              target="_blank"
            >
              {<entry.icon />}
            </a>
          ))}
        </div>
      </div>

      <div className="wrapper-s subsection t-center">
        <h3 className="t-heading-m">Merchandise</h3>

        <p>
          Let’s face it, MOSS is badass. We’re a less-audacious Greenpeace. An
          older, wiser Greta. A perfectly prepared Beyond Meat burger. We should
          have merchandise.
        </p>

        <p className="t-italic">Oh wait, we do.</p>

        <p>
          We aim to provide merchandise that meets our stringent
          socio-ecological norms. That’s why you’ll find that all our T-Shirts
          have a great story either because they support local communities or
          protect local ecosystems.
        </p>

        <p>
          Our shirts are available in S/M/L sizes (they fit quite small; we're
          all wearing L), in colors light gray, blue, and brown, and each shirt
          features a unique breast-pocket design.
        </p>

        <a
          className={styles.merchandise__cta}
          href="mailto:info@mosscc.org?subject=T-Shirt%20Order&body=Hello%20MOSS%2C%0A%0AI'd%20like%20to%20order%20one%20of%20those%20T-Shirts%20mentioned%20on%20your%20site!%0A%0ASize%3A%20S%20%2F%20M%20%2F%20L%0AColor%3A%20Light%20Gray%20%2F%20Blue%20%2F%20Brown%0AName%3A%0AAddress%3A%0A%0AThanks!"
          rel="noopener noreferrer"
          target="_blank"
        >
          Order your MOSS T-Shirt today!
        </a>

        <p className="t-bold t-italic">
          $35 per item excl. shipping and handling
        </p>

        <p className="t-italic">
          Every single shirt you order is made by a Myanmar person, using only
          locally sourced materials, and with groups who focus on environmental
          sustainability.
        </p>

        <div className={styles.merchandise__photo}>
          <Img fluid={data.merch1.childImageSharp.fluid} />
        </div>
      </div>
    </section>
  )
}

export default Support
